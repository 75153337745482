import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <div>
    <div className="blog-excerpt">
      <h5 style={{ textAlign: "right" }}>{post.frontmatter.date}</h5>
      <h5
        style={{
          textAlign: "center",
        }}
      >
        <Link
          to={"/" + post.frontmatter.slug}
          style={{
            color: `#000000`,
            textDecoration: `none`,
          }}
        >
          <h3 style={{ margin: "2rem auto", fontWeight: "bold" }}>
            {post.frontmatter.title}
          </h3>
          <div
            style={{
              textAlign: "justify",
            }}
          >
            {post.excerpt}
          </div>
        </Link>
      </h5>
    </div>
  </div>
)

export default PostLink
